<template>
    <div class="kus-home-login">
        <v-tabs v-model="tab" centered class="mb-5">
            <v-tab href="#form">Login</v-tab>
            <v-tab :to="{ name: 'content', params: { contentId: 'cgu' } }">CGU</v-tab>
            <v-tab :to="{ name: 'content', params: { contentId: 'dp' } }">RGPD</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="flex-grow-1">
            <v-tab-item value="form" eager>
                <v-form
                    ref="form"
                    v-model="valid"
                    @submit.stop.prevent="submit"
                    class="kus-home-login--form"
                    :style="{ 'min-width': `${$vuetify.breakpoint.mdAndUp ? 400 : 1}px` }">

                    <v-img src="/kushim-logo.png" class="kus-login-logo" max-width="100" title="kushim" alt="kushim" />
                    <v-card flat class="kus-home-login--card" :loading="loading">
                        <v-card-text>
                            <v-text-field
                                class="mt-5"
                                ref="field"
                                type="email"
                                name="email"
                                v-model="credentials.email"
                                autocapitalize="none"
                                :rules="[required, emailed]">
                                <template #label>
                                    <v-icon style="vertical-align: middle" small>$vuetify.icons.loginEmail</v-icon>
                                    {{ $t('home.publicLogin.email') }}
                                </template>
                            </v-text-field>

                            <v-text-field
                                type="password"
                                name="password"
                                v-model="credentials.password"
                                :rules="[required]">
                                <template #label>
                                    <v-icon style="vertical-align: middle" small>$vuetify.icons.loginPassword</v-icon>
                                    {{ $t('home.publicLogin.password') }}
                                </template>
                            </v-text-field>

                            <div class="d-flex">
                                <kus-home-public-subscribe-btn :email="credentials.email" />
                                <v-spacer />
                                <kus-home-public-password-lost-btn :email="credentials.email" />
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                type="submit"
                                class="kus-btn--login"
                                color="primary"
                                :loading="loading"
                                :disabled="!valid">
                                {{ $t('home.publicLogin.login') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import KusHomePublicPasswordLostBtn from '@/modules/home/public/KusHomePublicPasswordLostBtn'
import KusHomePublicSubscribeBtn from '@/modules/home/public/KusHomePublicSubscribeBtn'
import KusBaseRules from '@/modules/base/KusBaseRules'

export default {
    name: 'kus-home-public-login',
    components: { KusHomePublicPasswordLostBtn, KusHomePublicSubscribeBtn },
    data: () => ({
        credentials: {},
        valid: false,
        loading: false,
        required: KusBaseRules.required,
        emailed: KusBaseRules.emailed,
        tab: 'form'
    }),
    mounted() {
        window.setTimeout(() => this.$refs.field.focus(), 200)
    },
    methods: {
        submit() {
            if (!this.$refs.form.validate()) {
                return
            }
            this.loading = true
            return this.$store.dispatch('auth/login', this.credentials)
                .then(user => {
                    this.$router.push({
                        name: user.currentClientId ? 'line' : 'home'
                    })
                })
                .finally(() => (this.loading = false))
        }
    }
}
</script>

<style lang="sass">
.kus-home-login
    display: flex
    align-items: center
    flex-direction: column

.kus-home-login--card
    background-color: transparent !important

.kus-home-login--form
    margin: 0 auto

    .kus-login-logo
        margin: 0 auto

    .kus-btn--login
        display: block
        margin: 0 auto

    .kus-btn--more
        text-transform: none

</style>
